/*
widget_archive
widget_categories
widget_nav_menu
widget_meta
widget_pages
widget_recent_comments
widget_recent_entries
*/
.widget_archive,
.widget_categories,
   //widget pages has following div inside:
.widget_nav_menu,
   //.menu-all-pages-container,
.widget_meta,
.widget_pages,
.widget_recent_comments,
.widget_recent_entries {
   ul {
      padding: 0;
      margin: 0;
      list-style: none;
      width: 100%;

      ul {
         li {
            padding-left: 20px;

            &:last-child {
               padding-bottom: 0;
               border-bottom: none;
            }
         }
      }
   }

   li {
      border-top: 1px solid $borderColor;
      border-bottom: 1px solid $borderColor;

      &:before {
         @include widget-a-before();
      }


   }

   li + li {
      border-top: none;
   }

   //fix for unit test
   //empty menu item
   a:empty,
   li > a:not([href]) {
      display: none !important;
   }
}

.widget_archive,
.widget_categories,
   //widget pages has following div inside:
.widget_nav_menu,
   //.menu-all-pages-container,
.widget_meta,
.widget_pages {
   li {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 0;

      &:before {
         @include widget-a-before();
      }

      a {
         padding: 20px 0;
         display: inline-block;
         min-height: 70px;
         font-weight: 600;
      }

      span {
         margin-left: auto;
      }

      .children,
      .sub-menu {
         a {
            font-weight: 500;
         }
      }
   }
}

.widget_recent_comments,
.widget_recent_entries {
   li {
      min-height: 70px;
      padding: 20px 0;
   }
}

.page_footer {
   .side_menu.widget_nav_menu {
      li {
         display: inline-block;
         position: relative;
         border: none;
         padding-top: 0;
         padding-bottom: 0;
         margin-left: 15px;
         margin-right: 15px;

         a {
            font-size: 14px;
            letter-spacing: .1em;
            font-weight: 600;
            text-transform: uppercase;
            position: relative;
            color: $darkColor;

            &::before {
               content: '';
               position: absolute;
               bottom: 0;
               left: 0;
               width: 100%;
               height: 1px;
               background-color: $darkgreyColor;
               transition: transform .4s cubic-bezier(.85, .26, .17, 1);
               transform: scaleX(0);
               transform-origin: 100% 50%;
            }

            &.active,
            &:hover {
               &::before {
                  transform-origin: 0 50%;
                  transform: scaleX(1);
               }
            }
         }

         @media (min-width: 1440px) {
            &:not(:last-child) {
               margin-left: 30px;
               margin-right: 30px;
            }
         }
      }
   }
}


//nav menu in topline and copyright
.page_copyright, .page_topline {
   .widget_nav_menu li {
      display: inline-block;
      position: relative;
      border: none;
      padding-right: 5px;
      padding-top: 0;
      padding-bottom: 0;
   }

   .widget_nav_menu li:before {
      content: '';
      position: absolute;
      padding: 0;
      left: -5px;
      top: 5px;
      bottom: 5px;
      width: 1px;
      background-color: $borderColorRGBA;
      opacity: 0.3;
      display: block;
   }

   .widget_nav_menu li:first-child:before {
      display: none;
   }

   .widget_nav_menu a {
      padding: 0 10px;
   }

   .widget_nav_menu a:before {
      display: none;
   }
}

//dropdown lists
.widget_categories,
.widget_archive {
   select {
      width: 100%;
   }
}

.ds {
   .widget_text {
      strong {
         color: $lightColor;
      }

      a {
         color: $fontColor;

         &:hover {
            color: $lightColor;
         }
      }
   }
}

.widget_text {
   img + p {
      margin-top: 25px;
   }

   p + .social-icons {
      margin-top: 25px;
   }

   strong {
      color: $darkgreyColor;
   }

   ul {
      list-style: none;
      padding-left: 0;

      li {
         &:before {
            @include widget-a-before();
         }
      }
   }
}

.widget_post_tabs {
   .nav-tabs {
      .nav-link {
         border: none;
      }
   }

   .tab-content {
      padding: 50px;
      border-left: none;
      border-right: none;
      border-bottom: none;
   }
}

.widget_nav_menu {
   &.side_menu {
      li {
         a {
            min-height: unset;
         }
      }
   }
}
