/*
** Flexslider
* http://www.woothemes.com/flexslider/
*
* Copyright 2012 WooThemes
* Free to use under the GPLv2 license.
* http://www.gnu.org/licenses/gpl-2.0.html
*
* Contributing author: Tyler Smith (@mbmufffin)
*/

/* Browser Resets */
.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus {
  outline: none;
}

.slides,
.flex-control-nav,
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* FlexSlider Necessary Styles */
.flexslider {
  margin: 0;
  padding: 0;
}

.flexslider .slides > li {
  display: none;
  -webkit-backface-visibility: hidden;
}

/* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides img {
  width: 100%;
  display: block;
}

.flex-pauseplay span {
  text-transform: capitalize;
}

/* Clearfix for the .slides element */
.slides:after {
  content: "\0020";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

html[xmlns] .slides {
  display: block;
}

* html .slides {
  height: 1%;
}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
* include js that eliminates this class on page load */
.no-js .slides > li:first-child {
  display: block;
}

/* FlexSlider Default Theme */
.flexslider {
  margin: 0 0;
  position: relative;
  zoom: 1;
}

.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.loading .flex-viewport {
  max-height: 300px;
}

.flexslider .slides {
  zoom: 1;
}

/* Direction Nav */
.flex-direction-nav {
  *height: 0;
}

.flex-direction-nav a {
  text-decoration: none;
  display: block;
  width: 40px;
  height: 40px;
  margin: -20px 0 0;
  position: absolute;
  top: 50%;
  z-index: 10;
  overflow: hidden;
  opacity: 0;
  cursor: pointer;
  font-size: 0;
  color: transparent;
  text-align: center;
}

.flex-direction-nav .flex-prev {
  left: 40px;
}

.flex-direction-nav .flex-next {
  right: 40px;
  text-align: right;
}

.flexslider:hover .flex-prev {
  opacity: 0.7;
}

.flexslider:hover .flex-next {
  opacity: 0.7;
}

.flexslider:hover .flex-next:hover, .flexslider:hover .flex-prev:hover {
  opacity: 1;
}

.flex-direction-nav .flex-disabled {
  opacity: 0;
  filter: alpha(opacity=0);
  cursor: default;
}

.flex-direction-nav a:before {
  font-family: "FontAwesome";
  font-size: 20px;
  content: '\f104';
  color: $lightColor;
  position: absolute;
  top: 5px;
  left: 12px;
}

.flex-direction-nav a.flex-next:before {
  content: '\f105';
  left: 14px;
}

/* Pause/Play */
.flex-pauseplay a {
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 5px;
  left: 10px;
  opacity: 0.8;
  z-index: 10;
  overflow: hidden;
  cursor: pointer;
  color: #000;
}

.flex-pauseplay a:before {
  font-family: 'FontAwesome';
  font-size: 20px;
  display: inline-block;
  content: "\f01d";
}

.flex-pauseplay a:hover {
  opacity: 1;
}

.flex-pauseplay a.flex-play:before {
  content: "\f01d";
}

/* Control Nav */
.flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: 20px;
  text-align: center;
  z-index: 10;
  //removing default slide numbers in in dots
  color: transparent;
  font-size: 0;
  line-height: 0;
}

.flex-control-nav li {
  text-align: center;
  display: inline-block;
  padding: 0 14px;
  zoom: 1;
  *display: inline;
}

.flex-control-paging li a {
  width: 8px;
  height: 8px;
  display: block;
  border: 1px solid $lightColor;
  cursor: pointer;
  border-radius: 50%;
  opacity: 0.5;
  .ds &,
  .cs & {
    border-color: $lightColor;
  }
}

.flex-control-paging li a:hover {
  background-color: $lightColor;
  border-color: $lightColor;
  opacity: 1;
}

.flex-control-paging li a.flex-active {
  background-color: $lightColor;
  border-color: $lightColor;
  opacity: 1;
}

//using in woo
.flex-control-thumbs {
  margin: 1px -1px;
  position: static;
  overflow: hidden;
}

.flex-control-thumbs li {
  width: 25%;
  float: left;
  margin: 0 0 1px;
  padding: 0 0 0 1px;
}

.flex-control-thumbs img {
  width: 100%;
  display: block;
  opacity: .7;
  cursor: pointer;
}

.flex-control-thumbs img:hover {
  opacity: 1;
}

.flex-control-thumbs .flex-active {
  opacity: 1;
  cursor: default;
}

/* custom nav for flex slider*/
.flex-custom-nav {
  text-align: center;
  position: absolute;
  bottom: 38px;
  right: 0;
  left: 0;
  z-index: 3;
  line-height: 1;
  transition: all 0.3s ease-in-out;
  .flex-control-nav {
    width: auto;
    display: inline-block;
    position: static;
  }
  .flex-custom-link {
    font-size: 12px;
    position: relative;
    bottom: 7px;
    margin: 0 8px;
    color: $darkgreyColor;
    .ds &,
    .cs & {
      color: $lightColor;
    }
    &:hover {
      color: $colorMain;
    }
  }
  .page_slider:hover & {
    opacity: 1;
  }
}