/*
** WordPress Page Links and Pagination
*/
.ds {
   .page-item,
   .nav-links,
   .page-links,
   .nav-links .page-numbers {
	  & > span,
	  & > a:not(.btn),
	  li > span,
	  li > a:not(.btn) {
		 color: $lightColor;

		 &:hover {
			color: $darkColor;
		 }
	  }

	  &.active > a,
	  &.active > .page-link,
	  .current {
		 color: $darkColor;

		 &::before {
			border-color: rgba($lightColor, .1);
		 }
	  }
   }
}

.item-content {
   a + .page-item,
   a + .nav-links,
   a + .page-links {
	  margin-top: 30px;
   }

   .page-item,
   .nav-links,
   .page-links {
	  justify-content: flex-start;

	  a:first-child {
		 margin-left: 0;
	  }
   }
}

.nav-links .page-numbers {
   & > span,
   & > a:not(.btn),
   li > span,
   li > a:not(.btn) {
	  &.next {
		 font-size: 0;

		 &::before {
			content: "\e92b";
			font-family: "icomoon", sans-serif;
			font-size: 12px;
		 }
	  }

	  &.prev {
		 font-size: 0;

		 &::before {
			content: "\e92b";
			font-family: "icomoon", sans-serif;
			position: relative;
			transform: rotate(180deg);
			font-size: 12px;
		 }
	  }
   }
}

.page-item.disabled .page-link {
   background-color: transparent !important;
   opacity: .5;
}

.nav-links ul.page-numbers {
   display: inline-flex;
   padding-left: 0;
}

.page-links {
   width: 100%;
}

.page-item,
.nav-links,
.page-links,
.nav-links .page-numbers {
   clear: both;
   list-style: none;

   &:first-child,
   &:last-child {
	  & > span,
	  & > a:not(.btn),
	  li > span,
	  li > a:not(.btn) {
		 padding: 0;
	  }
   }

   & > span,
   & > a:not(.btn),
   li > span,
   li > a:not(.btn) {
	  display: inline-flex;
	  align-items: center;
	  justify-content: center;
	  padding: 1px 0 0 0;
	  color: $darkgreyColor;
	  background-color: transparent;
	  margin-right: 10px;
	  margin-bottom: 10px;
	  margin-left: 0;
	  text-align: center;
	  font-size: 14px;
	  width: 50px;
	  height: 50px;
	  position: relative;
	  border: none;
	  transition: all .3s ease-in-out;

	  &:hover:not(.prev):not(.next) {
		 background-color: $darkgreyColor;
		 color: $lightColor;
	  }

	  &.prev:hover {
		 transform: translateX(-10%);
		 color: $colorMain;
	  }

	  &.next:hover {
		 transform: translateX(10%);
		 color: $colorMain;
	  }
   }

   .page-links-title {
	  border: none;
	  padding: 0 !important;
   }

   &.active > a,
   &.active > .page-link,
   .current {
	  background-color: $darkgreyColor;
	  color: $lightColor;
	  cursor: not-allowed;
   }

   &.next {
	  i {
		 position: relative;
		 transform: rotate(180deg);
	  }
   }

   &.next,
   &.prev {
	  i {
		 font-size: 9px;
	  }

	  & > .page-link:hover {
		 background-color: transparent;
		 color: $darkgreyColor;
		 opacity: .6;
	  }
   }

   & > span:not(.current) {
	  pointer-events: none;
   }

   .fa {
	  font-size: 10px;
   }
}

.page-links-title {
   width: auto !important;
   padding: 0 10px !important;
}

//page and comments links smaller
.comments-pagination,
.page-links {
   .page-numbers,
   & > span,
   a {

   }
}

.page-links a:first-child {
   margin-left: 1em;
}

//blog styles
.pagination {
   margin-bottom: -10px;
   margin-top: 60px;
   @media (min-width: 1200px) {
	  margin-top: 80px;
   }
}

//blog post
.post-nav {
   margin: 60px 0 0;
   position: relative;

   .nav-links {
	  display: flex;
	  flex-wrap: wrap;
	  justify-content: space-between;

	  & > div {
		 width: 48%;
		 height: auto;
		 position: relative;
		 padding: 30px;
		 text-align: right;
		 background-position: 50% 28%;
		 transition: 0.2s opacity ease;

		 @media (min-width: 1200px) {
			padding: 120px 80px;
		 }

		 &::before {
			opacity: .3;
		 }

		 &:hover {
			opacity: 0.9;
		 }

		 @media(max-width: 575px) {
			width: 100%;
			&:first-child {
			   margin-bottom: 30px;
			}
		 }

		 .fa {
			font-size: 20px;
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
		 }

		 &:first-child {
			text-align: left;

			.fa {
			   right: auto;
			   left: 0;
			}
		 }
	  }

	  .nav-subtitle {
		 @include small-text();
		 color: $lightColor;
		 display: inline-block;
	  }

	  .nav-title {
		 font-size: 20px;
		 display: block;
		 margin-top: 10px;
		 text-transform: capitalize;
		 line-height: 1.3;
	  }

	  a {
		 @include cover();
	  }
   }

   //for .cover-image
   .post-nav-image {
	  display: none;
   }

   .nav-previous {
	  .post-nav-text-wrap {
		 text-align: right;
	  }

	  .nav-subtitle {
		&::before{
		   content: "\e920";
		   font-family: 'icomoon';
		   font-size: 5px;
		   margin-right: 10px;
		   bottom: 3px;
		   position: relative;
		}
	  }
   }

   .nav-next {
	  .post-nav-text-wrap {
		 text-align: left;
	  }

	  .nav-subtitle {
		&::after{
		   content: "\e901";
		   font-family: 'icomoon';
		   font-size: 5px;
		   margin-left: 10px;
		}
	  }
   }
}