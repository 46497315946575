/* Media Items - Common Side and Top Image Items */
//making media
.item-content p:last-child {
   margin-bottom: 0;
}

//image overlap borders for border items - remove if not needed
.bordered .item-media,
.bordered .entry-thumbnail {
   margin: -1px -1px -1px -1px;
   z-index: 1;
}

.bordered .item-media.cover-image {
   //border * 2
   height: calc(100% + 2px);
}

.bordered.full-padding .item-media {
   margin: 0;
}

/* side and vertical items paddings */
.full-padding {
   padding: 40px;
}

.content-padding .item-content {
   padding: 40px;
}

.content-padding.side-item .item-content {
   padding: 0;

   @media (min-width: 767px) {
	  padding: 0 40px;
   }

   &.big-content {
	  @media (min-width: 1200px) {
		 padding-left: 170px;
		 padding-right: 170px;
	  }
   }

   @media (min-width: 1200px) {
	  padding-left: 80px;
	  padding-right: 80px;
   }
}

//make padding smaller on small screens
@media (max-width: 992px) {
   .full-padding {
	  padding: 30px;
   }
   .content-padding .item-content {
	  padding: 30px;
   }
}

/*side item*/
.side-item {
   h2, h3, h4 {
	  line-height: 1;
   }

   [class*="col-"] {
	  padding: 0;
	  margin: 0;
   }

   .row {
	  margin: 0;
	  @media (max-width: 1199px) {
		 align-items: center;
	  }
   }

   //color item title
   .item-title + .item-content {
	  padding-top: 30px;
   }

   [class*="col-"] > .item-title {
	  padding: 15px 30px;

	  h2, h3, h4 {
		 margin: 0;
	  }
   }

   //color item title not inside .item-content - border width 1px
   &.bordered [class*="col-"] > .item-title {
	  margin: -1px;
   }
}

/*-xs- -sm- side-item paddings and margins*/
@media (max-width: 767px) {
   .no-content-padding,
   .full-padding {
	  [class*='col-sm-'],
	  [class*='col-xs-'] {
		 .item-content {
			padding-left: 30px;
		 }

		 //bootstrap 3
		 &[class*='sm-pull-'],
		 &[class*='xs-pull-'],
			//bootstrap 4
		 &[class*='order-sm-1'],
		 &[class*='order-xs-1'] {
			.item-content {
			   padding-left: 0;
			   padding-right: 30px;
			}
		 }
	  }

	  .col-sm-12 .item-content,
	  .col-xs-12 .item-content {
		 padding-top: 30px;
		 padding-left: 0;
		 padding-right: 0;
	  }
   }
}

/*-md- side-item paddings and margins*/
@media (min-width: 768px) {
   .no-content-padding,
   .full-padding {
	  [class*='col-md-'] {
		 .item-content {
			padding-left: 30px;
		 }

		 //bootstrap 3
		 &[class*='md-pull-'],
			//bootstrap 4
		 &[class*='order-md-1'] {
			.item-content {
			   padding-left: 0;
			   padding-right: 30px;
			}
		 }
	  }

	  .col-sm-12 .item-content {
		 padding-top: 30px;
		 padding-left: 0;
		 padding-right: 0;
	  }
   }
}

/*-lg- side-item paddings and margins*/
@media (min-width: 992px) {
   .no-content-padding,
   .full-padding {
	  [class*='col-lg-'] {
		 .item-content {
			padding-left: 30px;
		 }

		 //bootstrap 3
		 &[class*='lg-pull-'],
			//bootstrap 4
		 &[class*='order-lg-1'] {
			.item-content {
			   padding-left: 0;
			   padding-right: 30px;
			}
		 }
	  }

	  .col-md-12 .item-content {
		 padding-top: 30px;
		 padding-left: 0;
		 padding-right: 0;
	  }
   }
}

/*-xl- side-item paddings and margins*/
@media (min-width: 1200px) {
   .no-content-padding,
   .full-padding {
	  [class*='col-xl-'] {
		 .item-content {
			padding-left: 30px;
		 }

		 &[class*='order-xl-1'] {
			.item-content {
			   padding-left: 0;
			   padding-right: 30px;
			}
		 }
	  }

	  .col-lg-12 .item-content {
		 padding-top: 30px;
		 padding-left: 0;
		 padding-right: 0;
	  }
   }
}

/* vertical media item */
.vertical-item {
   .item-media + .item-content {
	  padding-top: 25px;
   }

   &.content-half-padding .item-content {
	  @media (min-width: 1200px) {
		 padding-right: 80px;
	  }
   }

   &.content-padding {
	  .item-content {
		 padding: 50px;
		 @media(max-width: 1199px) {
			padding: 30px;
		 }
	  }

	  &.padding-small .item-content {
		 padding: 40px 40px 40px;
	  }
   }

   &.content-absolute {
	  position: relative;

	  .item-content {
		 position: absolute;
		 bottom: 0;
		 left: 0;
		 right: 0;
		 padding: 20px 30px 20px;
		 z-index: 4;
	  }

	  &.vertical-center .item-content {
		 top: 0;
	  }
   }

   //title at the bottom of item
   & + .item-title {
	  padding: 10px 30px;

	  h2, h3, h4 {
		 margin: 0;
	  }
   }
}

/* item meta */
.item-meta {
   font-size: 14px;
   font-weight: 600;
   text-transform: uppercase;
   letter-spacing: 0.1em;
   line-height: 1.6;

   i {
	  width: 1em;
	  text-align: center;
	  position: relative;
	  font-size: 1.2em;
   }

   span {
	  padding: 0 0.5em;
   }
}

/* item media & item links */
.item-media {
   position: relative;
   overflow: hidden;
   //fixing cut images on extra large screens
   img {
	  width: auto;
	  transition: transform .3s ease-in-out;
   }

   &:hover{
	  img{
		 transform: scale(1.05);
	  }
   }

   .owl-carousel{
	  .owl-nav{
		 position: absolute;
		 bottom: 40px;
		 left: 50%;
		 transform: translateX(-50%);

		 i{
			color: $lightColor;
		 }
	  }
   }

   &.cover-img-full{
	  img{
		 width: 100%;
		 height: 100%;
		 object-fit: cover;
	  }
   }

   &.cover-img{
	  @media (min-width: 1199px) {
		 img{
			width: auto;
			height: 100%;
			object-fit: cover;
		 }
	  }
   }

   &.cover-image {
	  height: 100%;
   }

   .custom-date-wrap {
	  position: absolute;
	  z-index: 1;
	  left: 0;
	  top: 0;
	  background-color: $lightColor;
	  text-align: center;
	  display: flex;
	  flex-direction: column;

	  .custom-date {
		 display: flex;
		 flex-direction: column;
		 justify-content: center;
		 align-items: center;
		 padding: 15px 20px;

		 span {
			font-weight: 600;
			line-height: 1;

			&:first-child {
			   font-size: 18px;
			   color: $darkgreyColor;
			}

			&:nth-child(2) {
			   font-size: 14px;
			   text-transform: uppercase;
			   letter-spacing: .1em;
			   margin-top: 8px;
			}
		 }
	  }

	  .custom-time {
		 background-color: $colorMain;
		 display: inline-flex;
		 justify-content: center;
		 padding: 8px;

		 span {
			color: $lightColor;
			font-size: 14px;
			text-transform: uppercase;
			letter-spacing: .1em;
			line-height: 1;
		 }
	  }
   }
}

.media-links {
   text-align: center;
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   //.links-wrap
   div {
	  position: absolute;
	  top: 50% !important;
	  width: 100%;

	  // media-link a height 60/2
	  transform: translateY(-50%);
	  z-index: 4;
	  //making links to top right corner when absolute content is used
	  //to avoid overlap the content
	  .content-absolute & {
		 display: flex;
		 top: 0;
		 margin-top: 0;
		 justify-content: flex-end;

		 a {
			transition: all 0.35s ease 0.1s;

			&:hover {

			}

			&:first-child {
			   transition-delay: 0s;
			}
		 }
	  }
   }

}

//overlay
.media-links:before {
   content: '';
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background-color: rgba($darkgreyColor, 0.4);
   opacity: 0;
   transition: all 0.2s linear;
}

[class*="-item"]:hover .media-links:before {
   opacity: 0.5;
}

.media-links a.abs-link {
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   opacity: 0;

   [class*="-item"]:hover & {
	  opacity: 1;
   }

   &:before {
	  content: '+';
	  font-size: 60px;
	  position: absolute;
	  top: 50%;
	  left: 50%;
	  line-height: 50px;
	  width: 50px;
	  height: 50px;
	  margin-top: -25px;
	  margin-left: -25px;
	  text-align: center;
	  color: $colorMain;
   }
}

.media-links a.default-link {
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   opacity: 0;

   [class*="-item"]:hover & {
	  opacity: 1;
   }
}

[class*="-item"] {
   .links-wrap {
	  position: absolute;
	  width: 100%;
	  height: 100%;
	  top: 0;
	  bottom: 0;
	  margin-top: 0;

	  a {
		 position: absolute;
		 left: 20%;
		 top: 50%;
		 transform: translate(-50%, -50%);
		 display: inline-block;
		 z-index: 5;
		 font-size: 60px;
		 text-align: center;
		 opacity: 0;
		 transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
	  }

	  a.link-zoom {
		 color: $lightColor;
	  }

	  a.link-anchor {
		 color: $lightColor;

		 //delay for second link
		 transition-delay: 0.1s;

	  }

	  a:before {
		 content: "\e925";
		 font-family: 'icomoon';
	  }

	  a.link-anchor:before {
		 content: "\e916";
	  }
   }
}

[class*="-item"]:hover {
   .links-wrap a {
	  left: 50%;
	  opacity: 1;
   }

   .media-links div {
	  opacity: 1;
   }
}

/* item icons */
.item-icons {
   margin-top: 35px;
   font-size: 12px;

   .content-padding & {
	  margin-top: 0;
   }

   i {
	  padding: 0 4px;
   }

   .col {
	  text-align: center;
	  padding-top: 15px;
	  padding-bottom: 20px;
   }
}
