/*
** WordPress Required
*/
.alignnone {
  margin: 10px 0 30px;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 10px auto 30px auto;
}

.alignright {
  float: right;
  margin: 10px 0 30px 40px;
  max-width: 50%;
}

.alignleft {
  float: left;
  margin: 10px 40px 30px 0;
  max-width: 50%;
}

a img.alignright {
  float: right;
  margin: 10px 0 30px 40px;
}

a img.alignnone {
  margin: 10px 0 30px;
}

a img.alignleft {
  float: left;
  margin: 10px 40px 30px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto
}

.wp-caption {
  text-align: center;
}

.wp-caption.alignnone {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
  margin: 5px 0 20px 20px;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px;
}

/* Text meant only for screen readers. */
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  white-space: nowrap;
  height: 1px;
  width: 1px;
  overflow: hidden;
  //fix for search and subscribe widgets

  margin: 0;
  padding: 0;
}

.screen-reader-text:focus {
  clip: auto !important;
  display: block;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000; /* Above WP toolbar. */
}

.sticky,
.bypostauthor {
  position: relative;
}