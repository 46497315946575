/* 
** Template Specific Styles
*/

#box_wrapper > section,
#box_wrapper > div > section {
   overflow: hidden;
}


#box_wrapper > section.page_toplogo,
#box_wrapper > section.overflow-visible,
#box_wrapper > div > section.overflow-visible {
   overflow: visible;

   @media (max-width: 575px){
      overflow: hidden!important;
   }
}

#box_wrapper > section.page_topline,
#box_wrapper > section.page_toplogo{
   overflow: visible!important;
}

.overflow-visible{
   @media (max-width: 575px){
      overflow: hidden!important;
   }
}


//featured person on three different background sections
img.absolute_image {
   position: absolute;
   z-index: 1;
   top: 50px;
}

.image-rounded-cross-borders {
   position: relative;
   margin: 50px 0 60px;

   &:before,
   &:after {
      content: '';
      position: absolute;
      width: 1px;
      height: 30px;
      background-color: $borderColor;
      top: -30px;
      left: 50%;
   }

   &:after {
      top: auto;
      bottom: -30px;
   }

   .box-shadow {
      padding: 9px;
      border-radius: 50%;

      &:before,
      &:after {
         content: '';
         position: absolute;
         height: 1px;
         width: 30px;
         background-color: $borderColor;
         top: 50%;
         left: -30px;
         @media(max-width: 1199px) {
            display: none;
         }
      }

      &:after {
         left: auto;
         right: -30px;
      }
   }

   img {
      border-radius: 50%;
   }
}

.btn-action {
   i {
      width: 1em;
      height: 1em;
      text-align: center;

      &.fa-chevron-right {
         position: relative;
         left: 2px;
      }
   }
}

.partners-carousel {
   a {
      opacity: 0.2;

      &:hover {
         opacity: 0.8;
      }
   }

   .center a {
      opacity: 1;
   }
}

//topline and toplogo
.dropdown-shopping-cart {
   position: relative;
   font-weight: 700;

   .page_toplogo & {
      margin-left: 24px;
   }

   i {
      font-size: 28px;
      padding-right: 14px;

      .page_toplogo & {
         position: relative;
         top: 0.18em;
      }
   }

   .badge {
      position: absolute;
      left: 24px;

      .page_toplogo & {
         top: -0.3em;
      }

      padding: .28em .5em .2em;
      border-radius: 1em;
   }

   &:hover {
      color: inherit;
   }

   & + .dropdown-menu {
      @media (min-width: 576px) {
         min-width: 420px;
      }
      margin-top: 50px;
   }
}

.header_absolute {
   &.cover-background,
   &.s-parallax {
      background-image: url("../images/parallax/video_section_background.jpg");
   }
}

.page_title {
   background-image: url("../images/slide01.jpg");

   &.cover-background,
   &.s-parallax {
      background-image: url("../images/parallax/video_section_background.jpg");
   }

   &.line-gradient {
      &::after {
         content: "";
         background: linear-gradient(90deg, $lightColor 1px, transparent 1px) 1px 0, transparent;
         background-size: 25% 1px;
         height: 100%;
         opacity: 0.15;
         pointer-events: none;
         position: absolute;
         width: 100%;
         left: 0;
         top: 0;
         z-index: 3;
      }
   }

   h1,
   h2 {
      margin-bottom: 0.2em;
      line-height: 1;
      font-size: 40px;
      @media(min-width: 992px) {
         &:last-child {
            margin-bottom: 0;
         }
      }

      @media (min-width: 1200px) {
         font-size: 70px;
      }
   }

   .small-title {
      margin-bottom: 0;

      & + .breadcrumb {
         font-size: 16px;
         letter-spacing: 0;
         text-transform: none;
         font-weight: 300;
      }
   }

   @media (min-width: 992px) {
      .title-inline {
         margin: 0;

         & + .breadcrumb {
            padding-left: 30px;
            margin-left: 30px;
            border-left: 1px solid $universalBorderColor;
         }
      }
   }

   .breadcrumb {
      line-height: 1.6;
   }

   [class*="container"] > .breadcrumb {
      position: absolute;
      right: 15px;
      bottom: 0;
      padding: 0 15px;
      background-color: $borderColorRGBA;
   }
}

.page_map {
   height: 500px;
   text-align: center;

   @media (min-width: 1200px) {
      height: 700px;
   }

   .marker {
      display: none;
   }
}

.map_marker_description {
   h3 {
      line-height: 1;
      margin-bottom: 10px;
   }

   max-width: 270px;

   img {
      max-width: 90% !important;
   }
}

//styling google info window
.gm-style .gm-style-iw {
   overflow: visible !important;
   text-align: center;
   line-height: 1.6em;

   & > *:first-child {
      position: relative;
   }

   &:before {
      content: '';
      display: block;
      position: absolute;
      border-top: 2px solid $colorMain;
      top: -25px;
      left: -16px;
      right: -16px;
      bottom: -25px;
      background: $lightColor;
      box-shadow: $boxShadow;
   }

   .icon-inline {
      display: inline-block;
      margin: 0.3em 0;

      .icon-styled {
         margin-right: 5px;

      }
   }
}

.before-footer {
   margin-bottom: 90px;
   @media (min-width: 1200px) {
      margin-bottom: -80px;

      .col-12 > div {
         position: relative;
         z-index: 5;
      }
   }

   &.before-footer1 .col-12 > div {
      background: url("../images/bg-before-footer.jpg") no-repeat center / cover;
   }

   &.before-footer2 {
      .before-footer-content {
         [class*='col-']:not(:last-child) {
            @media (min-width: 992px) {
               position: relative;
               &:after {
                  content: '';
                  position: absolute;
                  right: 0;
                  top: 50%;
                  transform: translateY(-50%);
                  width: 1px;
                  height: 50px;
                  display: inline-block;
                  background-color: $darkColor;
                  opacity: .1;
               }
            }
         }
      }
   }

   &-content {
      padding: 40px;
      @media (min-width: 1400px) {
         padding: 80px;
      }
   }

   .title-custom-footer {
      position: absolute;
      right: 40px;
      top: 0;
      bottom: 0;
      opacity: .3;

      .emptytext {
         -webkit-text-stroke-color: $lightColor;
         display: inline-block;
         line-height: 1;
         font-weight: 800;
         font-size: 240px;
      }

      @media (max-width: 1199px) {
         display: none;
      }
   }

   .cs {
      a {
         color: $darkColor;
         font-weight: 600;

         &:hover {
            color: $fontColor;
         }
      }
   }

   .btn {
      white-space: nowrap;
   }
}

/*footer social buttons that hidden on half of their height*/
.page_footer {

   //for hidden social icons
   overflow: hidden;

   .top-right-nav .owl-nav{
      top: -55px;
   }

   &.s-parallax {
      background-image: url("../images/parallax/video_section_background.jpg");
   }

   /*overriding Bootstrap Addons buttons*/
   @media (min-width: 576px) {
      a.border-icon:before,
      a.bg-icon:before,
      a.color-bg-icon:before {
         width: 4.20em;
         height: 4.20em;
         line-height: 4.20em;
      }
   }

   .side-icon-box {
      margin: 0 0 20px;

      .icon-styled + .media-body {
         margin-left: 20px;
      }
   }

   .col-md-12 {
      .widget_social_buttons {
         margin-bottom: 0;

         a {
            margin: 0 4px 70px;
            @media (min-width: 1200px) {
               transform: translateY(50%);
               margin-bottom: 0;
               &:hover {
                  transform: translateY(0);
               }
            }
         }
      }
   }
}

.footer-wrap{
   .footer-title-custom{
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      font-size: 240px;
      line-height: .8;
      white-space: nowrap;
      text-transform: uppercase;
      opacity: .1;
      margin: 0;
      span{
         display: inline-block;
      }

      @media (max-width: 1440px) {
         font-size: 200px;
      }
      @media (max-width: 1199px) {
         display: none;
      }
   }
}

.page_copyright {
   font-size: 14px;
   position: relative;

   &.cover-background,
   &.s-parallax {
      background-image: url("../images/parallax/video_section_background.jpg");
   }
}

//team hover info for .media-item
.item-hover-content {
   position: relative;
   overflow: hidden;

   .item-media {
      z-index: 2;
   }

   .item-content {
      position: absolute;
      right: 0;
      left: 0;
      box-shadow: $boxShadow;

      h4 {
         line-height: 1;
         letter-spacing: 0.15em;
         margin-bottom: 1.4em;

         span {
            font-weight: 900;
         }
      }
   }

   //overriding default media item content padding on big screens
   &.content-padding .item-content {
      @media (min-width: 1200px) {
         padding: 54px 40px;
      }
   }

   &:hover {
      overflow: visible;
      z-index: 3;
      box-shadow: $boxShadow;
   }
}

.content-show-hover {
   overflow: hidden;

   .item-content {
      top: 0;
      opacity: 0;
      transform: scale(1.2);
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      transition: all 0.15s ease-in-out;

      h4 {
         line-height: 1;
         letter-spacing: 0.15em;
         margin-bottom: 1.4em;

         span {
            display: block;
            font-weight: 900;
         }
      }
   }

   &:hover {
      box-shadow: $boxShadow;

      .item-content {
         transform: scale(1);
         opacity: 1;
      }
   }
}

//regular gallery item with absolute content and scaling header
.item-gallery {
   overflow: hidden;
   position: relative;
   background: transparent;

   .item-content {
      h4 {
         z-index: 5;
         transition: all 0.15s ease-in-out;
         opacity: 0;
         font-size: 20px;
         transform: scale(0);
      }
   }

   //hover styles
   &:hover {
      .item-content h4 {
         transform: scale(1);
         opacity: 1;
      }
   }
}

.title-custom {
   position: absolute;
   left: 0;
   right: 0;
   white-space: nowrap;
   overflow: hidden;
   line-height: 1.15;
   font-weight: 800;
   font-size: 80px;
   font-family: 'Montserrat';

   @media (min-width: 1200px) {
      font-size: 120px;
   }

   &.top {
      top: 0;

      span {
         top: 0;
      }
   }

   &.bottom {
      bottom: -0.2em;

      span {
         bottom: 0;
      }
   }

   span {
      position: relative;
      display: inline-block;
      //min-width: 100%;
      //animation: banner-animate 6s linear infinite forwards;
   }
}

@keyframes banner-animate {
   from {
      //left: 100%;
      transform: translateX(100%);
   }
   to {
      transform: translateX(-105%);
      //left: -100%;
   }
}

.service-icon {
   color: rgba($darkgreyColor, .1);
   margin-bottom: -60px;
   font-size: 256px;

   @media (max-width: 1199px) {
      font-size: 150px;
   }

   @media (max-width: 991px) {
      margin-bottom: -40px;
   }

   @media (max-width: 767px) {
      font-size: 100px;
      margin-bottom: -20px;
   }
}

.service-shortcode {
   padding: 40px;
   overflow: hidden;

   .image-back {
      position: absolute;
      left: 0;
      top: 0;
      transform: unset;
      transition: all .3s ease-in-out;
   }

   .emptytext {
      display: inline-block;
      position: relative;

      &::after {
         content: attr(data-content);
         position: absolute;
         left: 50%;
         top: 50%;
         transform: translate(-50%, -50%);
         opacity: 0;
         word-break: normal;
         -webkit-text-stroke-color: unset;
         -webkit-text-fill-color: $lightColor;
         transition: all .3s ease-in-out;
      }
   }

   h3 {
      @media (max-width: 1639px) {
         line-height: 1;
      }
      @media (min-width: 1640px) {
         font-size: 80px;
      }
   }

   &:hover {
      .image-back {
         transform: scale(1.05);
      }

      .emptytext::after {
         opacity: 1;
      }
   }

   @media (min-width: 1199px) {
      padding: 120px 40px;
   }
}

.firm-shortcode {
   display: grid;
   grid-template-columns: repeat(4, 1fr);
   grid-auto-rows: 200px;
   background-color: $greyColor;

   @media (max-width: 991px) {
      grid-template-columns: repeat(2, 1fr);
   }

   @media (max-width: 575px) {
      grid-template-columns: repeat(1, 1fr);
   }

   a {
      display: grid;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-items: center;
      border: 0.1em solid $borderColor;
      background-color: transparent;
      z-index: 1;
      position: relative;


      &::after {
         content: '';
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
         background-color: $darkgreyColor;
         width: 0;
         height: 0;
         z-index: -1;
         transition: all .3s ease-in-out;
      }

      &:hover {
         background-color: $darkgreyColor;
         border-color: $darkgreyColor;
         cursor: pointer;
         transition: all .3s ease-in-out .2s;;

         &::after {
            width: 100%;
            height: 100%;
         }

         img {
            filter: invert(20%);
         }
      }
   }
}

// Grid portfolio
.grid-container {
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   grid-auto-flow: dense;

   &.gallery-layout-1 {
      grid-auto-rows: 175px;
   }

   &.gallery-layout-2 {
      grid-auto-rows: 200px;
   }

   &.gap-0 {
      grid-gap: 0;
   }

   &.gap-5 {
      grid-gap: 5px;
   }

   &.gap-15 {
      grid-gap: 15px;
   }

   &.gap-30 {
      grid-gap: 30px;
   }

   &.gap-40 {
      grid-gap: 40px;
   }

   .grid-box {
      opacity: 1;
      transform: scale(1);
      transition: all .5s ease-in-out;
      grid-row: span 2;

      @media (min-width: 1200px) {
         &:nth-child(2),
         &:nth-child(4),
         &:nth-child(5) {
            grid-row: span 3;
         }
      }

      @media (max-width: 1199px) and (min-width: 768px) {
         &:nth-child(2),
         &:nth-child(3),
         &:nth-child(4),
         &:nth-child(5) {
            grid-row: span 3;
         }
      }

      @media (max-width: 767px) {
         &:nth-child(even) {
            grid-row: span 3;
         }
      }
   }

   .hide {
      opacity: 0;
      transform: scale(0);
   }

   .item-gallery,
   .item-media {
      width: 100%;
      height: 100%;
   }

   img {
      height: 100%;
      object-fit: cover;
   }

   @media (max-width: 1199px) {
      grid-template-columns: repeat(2, 1fr);
   }
   @media (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
   }
}

.comingsoon {
   background-image: url("../images/404.jpg");
}

/*
** Signature
*/

.ds {
   .signature {
      &-avatar {
      }
   }
}

.signature {
   display: flex;
   align-items: center;
   flex-wrap: wrap;
   margin-bottom: -25px;

   & > * {
      margin-bottom: 25px;

      &:not(:last-child) {
         margin-right: 25px;
      }
   }

   &-avatar {
      border-radius: 50%;

      img {
         border-radius: 50%;
         overflow: hidden;
      }
   }

   &-content {
      p {
         text-transform: uppercase;
         letter-spacing: .1em;
         font-size: 14px;
         font-weight: 600;
      }
   }
}

.video-section {
   background-image: url('../images/events/33.jpg');
   background-size: cover;

   .title-custom {
      text-transform: uppercase;
      @media (min-width: 1440px) {
         font-size: 240px;
      }
   }
}

.img-shortcode {
   position: relative;

   img{
      width: 100%;
   }

   @media (min-width: 1200px) {
      &::before {
         content: '';
         position: absolute;
         width: calc(100% + 80px);
         height: calc(100% - 80px);
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
         border: 1px solid $darkgreyColor;
         opacity: .1;
      }
   }

   .icon-box {
      position: absolute;
      left: 60px;
      bottom: 80px;
   }

   .iq-rotate-text {
      color: $lightColor;
      letter-spacing: .3em;
   }
}

.banner-section {
   .title-custom + .title-custom {
      margin-top: -0.2em;
   }
}


.slider-accordion {
   position: relative;
   overflow: hidden;

   @media (min-width: 1680px) {
      margin-left: 65px;
   }

   @media (min-width: 1800px) {
      margin-left: 120px;
   }

   &_shortcode {

   }

   &-content {
      background-color: transparent;
      padding: 0;

      .content {
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;
         min-height: 500px;
         padding: 90px 15px;
         @media (min-width: 1200px) {
            min-height: 700px;
         }
         @media (min-width: 1600px) {
            min-height: 880px;
            padding: 150px 30px;
         }
      }

      .tab-pane {

      }
   }

   &-nav {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: row;
      z-index: 3;

      .nav-item {
         flex-grow: 1;
         position: relative;

         a {
            background: transparent !important;
            position: absolute;
            right: 0;
            bottom: 0;
            padding-right: 40px;
            padding-bottom: 80px;
            min-width: auto;
            margin-right: 0;
            color: $lightColor !important;

            @media (max-width: 1199px) {
               padding: 50px 30px;
            }

            @media (max-width: 767px) {
               padding: 30px 15px;
            }

            &.active {
               padding-bottom: 60px;

               @media (max-width: 1199px) {
                  padding-bottom: 30px;
               }

               @media (max-width: 767px) {
                  padding-bottom: 15px;
               }
            }

            &::before {
               content: '';
               position: absolute;
               bottom: 0;
               right: 0;
               display: inline-block;
               width: 1px;
               height: 1000px;
               background-color: rgba($lightColor, .15);
            }
         }

         span {
            margin: 0;
            padding: 0;
            writing-mode: vertical-lr;
            transform: rotate(180deg);
         }
      }
   }

   h2 {
      margin: 0;
      line-height: 1;
   }

   h3 {
      margin: 0;
      line-height: 1;
   }

   h4 {
      margin: 0;
   }

   h5 {
      margin: 0;
   }

   p {
      margin: 0;
   }

   .bigger {
      line-height: 1;
   }

   h2.bigger {
      font-size: 60px;
   }

   h3.bigger {
      font-size: 50px;
   }

   h4.bigger {
      font-size: 40px;
   }

   h5.bigger {
      font-size: 30px;
   }

   p.bigger {
      font-size: 16px;
   }

   @media (min-width: 767px) {
      h2.bigger {
         font-size: 90px;
      }
      h3.bigger {
         font-size: 70px;
      }
      h4.bigger {
         font-size: 60px;
      }
      h5.bigger {
         font-size: 50px;
      }
   }

   @media (min-width: 1200px) {
      h2.bigger {
         font-size: 200px;
      }
      h3.bigger {
         font-size: 120px;
      }
      h4.bigger {
         font-size: 70px;
      }
      h5.bigger {
         font-size: 60px;
      }
      p.bigger {
         font-size: 20px;
      }
   }

   @media (min-width: 1600px) {
      h2.bigger {
         font-size: 480px;
         line-height: .7;
      }
      h3.bigger {
         font-size: 240px;
         line-height: .7;
      }
   }
}
