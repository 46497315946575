/*
** Widgets
*/
aside h2 {
   margin-bottom: 0;
   line-height: 1;
}

aside > * + * {
   margin-top: 60px;

   @media (min-width: 1200px) {
      margin-top: 80px;
   }
}

.widget {
   position: relative;

   & > h3,
   .widget-title {
      @include widget-title();
   }

   //for shortcodes demo
   & > h2:last-child {
      line-height: 1;
   }

   ul {
      counter-reset: li !important;
   }

   //our custom widgets with bootstrap .media element
   li.media {
      margin-bottom: 30px;
      position: relative;
      align-items: center;

      h4 {
         margin-top: 0;
      }

      &::before {
         content: counter(li);
         counter-increment: li;
         position: absolute;
         left: 0;
         top: 0;
         font-size: 14px;
         line-height: 1;
         color: $darkgreyColor;
         background-color: $lightColor;
         padding: 8px 12px;
         z-index: 2;
      }

      img {
         max-width: 130px;
      }

      h3, h4 {
         font-weight: 700;
         line-height: 1.4;
         margin-bottom: 0.5em;
         text-transform: none;
         font-size: inherit;
      }

      p {
         line-height: 1.4;
         margin-bottom: 0.5em;
      }
   }

   .page_footer & {
      & > h3,
      .widget-title {
         margin-bottom: 2.2em;
         font-size: 14px;
         letter-spacing: .1em;
         text-transform: uppercase;
         font-weight: 600;
      }


   }
}

.page_footer {
   .widget_nav_menu {
      li {
         border-color: transparent;

         &::before {
            display: none;
         }
      }

      a {
         padding: 8px 0;
         min-height: auto;
         color: $fontColor;
         font-weight: 400;
      }
   }
}

.widget-title.next-image-background {
   position: relative;
   z-index: 2;

   & + img {
      position: absolute;
      left: 0;
      top: -15px;
      opacity: 0.5;
   }

   .text-center & + img {
      left: 50%;
      transform: translateX(-50%);
   }
}

@media(max-width: 991px) {
   main + aside {
      margin-top: 60px !important;
   }
}

/* affix-aside */
.affix-aside {
   position: relative;

   &.affix {
      position: fixed;
      //oriented header height
      top: 100px;
   }

   &.affix-bottom {
      position: absolute !important;
   }

   &.affix-top {
      //width: inherit !important;
      //left: auto !important;
   }

   @media(max-width: 991px) {
      &,
      &.affix-top,
      &.affix,
      &.affix-bottom {
         position: static !important;
         width: 100% !important;
      }
   }
}

//p.stars removed
.stars {
   & > span {
      display: flex;
   }

   a {
      position: relative;
      width: 1em;
      color: transparent;
      text-indent: -999em;

      &:before {
         position: absolute;
         top: 0;
         left: 0;
         width: 1em;
         height: 1em;
         line-height: 1;
         text-indent: 0;
         font-family: 'FontAwesome';
      }

      &:before,
      &:hover ~ a:before {
         color: $fontColor;
         content: "\f006"
      }
   }

   &.selected a.active:before,
   &:hover a:before {
      content: "\f005";
      color: $colorMain;
      opacity: 1;
   }

   &.selected {
      a.active ~ a:before {
         content: "\f005";
         color: $fontColor;
         opacity: .75
      }

      a:not(.active):before {
         content: "\f005";
         color: $colorMain;
         opacity: 1
      }
   }
}

.star-rating {
   overflow: hidden;
   position: relative;
   height: 1em;
   line-height: 1;
   font-size: 1em;
   width: 5em;
   letter-spacing: 0.1em;
   font-family: 'FontAwesome';
   color: $darkgreyColor;

   &:before {
      content: '\f006\f006\f006\f006\f006';
      float: left;
      top: 0;
      left: 0;
      position: absolute;
      color: $fontColor;
   }

   span {
      overflow: hidden;
      float: left;
      top: 0;
      left: 0;
      position: absolute;
      padding-top: 1.5em;

      &:before {
         content: '\f006\f006\f006\f006\f006';
         top: 0;
         position: absolute;
         left: 0
      }

   }
}

@import 'widgets/search_mailchimp';
@import 'widgets/nav_cat_arch_meta_pages';
@import 'widgets/calendar';
@import 'widgets/rss';
@import 'widgets/tag_cloud';
@import 'widgets/flickr';
@import 'widgets/instagram';
@import 'widgets/access_press';
@import 'widgets/twitter';
@import 'widgets/post_slider';
@import 'widgets/post_tabs';
@import 'widgets/post_2cols';
