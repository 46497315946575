
/* sidebar scroll plugin */
.scroll-wrapper {
  overflow: hidden;
  padding: 0;
  position: relative;
  height: 100%;

  & > .scroll-content {
    border: none;
    box-sizing: content-box;
    height: auto;
    left: 0;
    margin: 0;
    max-height: none;
    max-width: none;
    overflow: scroll;
    padding: 0;
    position: relative;
    top: 0;
    width: auto;

    &::-webkit-scrollbar {
      height: 0;
      width: 0;
    }
  }
  &.scroll--rtl {
    direction: rtl;
  }
}

.scroll-element {
  box-sizing: content-box;
  display: none;

  div {
    box-sizing: content-box;
  }
  .scroll-bar,
  .scroll-arrow {
    cursor: default;
  }

  &.scroll-x.scroll-scrollx_visible,
  &.scroll-y.scroll-scrolly_visible {
    display: block;
  }
}

.scrollbar-macosx > .scroll-element,
.scrollbar-macosx > .scroll-element div {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10;
}

.scrollbar-macosx > .scroll-element div {
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}

.scrollbar-macosx > .scroll-element .scroll-element_track {
  display: none;
}

.scrollbar-macosx > .scroll-element .scroll-bar {
  background-color: rgba(127, 127, 127, 0.4);
  display: block;

  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;

  border-radius: 1px;

  transition: opacity 0.2s linear;
}

.scrollbar-macosx:hover > .scroll-element .scroll-bar,
.scrollbar-macosx > .scroll-element.scroll-draggable .scroll-bar {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
  opacity: 0.7;
}

.scrollbar-macosx > .scroll-element.scroll-x {
  bottom: 0;
  height: 0;
  left: 0;
  min-width: 100%;
  overflow: visible;
  width: 100%;
}

.scrollbar-macosx > .scroll-element.scroll-y {
  height: 100%;
  min-height: 100%;
  right: 0;
  top: 0;
  width: 0;
}

// scrollbar height/width & offset from container borders
.scrollbar-macosx > .scroll-element.scroll-x .scroll-bar {
  height: 7px;
  min-width: 10px;
  top: -9px;
}

.scrollbar-macosx > .scroll-element.scroll-y .scroll-bar {
  left: -9px;
  min-height: 10px;
  width: 7px;
}

.scrollbar-macosx > .scroll-element.scroll-x .scroll-element_outer {
  left: 2px;
}

.scrollbar-macosx > .scroll-element.scroll-x .scroll-element_size {
  left: -4px;
}

.scrollbar-macosx > .scroll-element.scroll-y .scroll-element_outer {
  top: 2px;
}

.scrollbar-macosx > .scroll-element.scroll-y .scroll-element_size {
  top: -4px;
}

// update scrollbar offset if both scrolls are visible
.scrollbar-macosx > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
  left: -11px;
}

.scrollbar-macosx > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
  top: -11px;
}