.quote-item {
  text-align: center;
  border: none;
  padding: 20px 0;
  @media (min-width: 768px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 70%;
  }
}

.quote-image {
  position: relative;
  display: inline-block;

  &:after {
    font-family: 'FontAwesome';
    content: "\f10d";
    position: absolute;
    color: $colorMain;
    background-color: $lightColor;
    height: 60px;
    width: 60px;
    text-align: center;
    line-height: 60px;
    border-radius: 50%;
    bottom: 0;
    right: 10px;
  }

  img {
    border-radius: 50%;
    box-shadow: $boxShadow;
    background: $lightColor;
    padding: 9px;
  }
  //testimonial author
  & + p {
    margin: 30px 0 20px;
  }

}