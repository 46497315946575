/*
** Intro Section Styles
*/

.page_slider {
   position: relative;
   overflow: hidden;
   //fix slider bug on small screens
   clear: both;

   &.line-gradient {
	  &::after {
		 content: "";
		 background: linear-gradient(90deg, $lightColor 1px, transparent 1px) 1px 0, transparent;
		 background-size: 25% 1px;
		 height: 100%;
		 opacity: 0.15;
		 pointer-events: none;
		 position: absolute;
		 width: 100%;
		 left: 0;
		 top: 0;
		 z-index: 3;
	  }
   }

   .custom-navigation {
	  position: absolute;
	  left: 0;
	  right: 0;
	  top: 50%;
	  transform: translateY(-50%);
	  z-index: 2;
	  display: flex;
	  justify-content: space-between;
	  padding-left: 80px;
	  padding-right: 80px;
	  color: $lightColor;

	  a {
		 display: inline-block;
		 stroke-dasharray: 70;
		 stroke-dashoffset: 150;
		 transition: stroke-dashoffset .6s ease;

		 span{
			color: $lightColor;
			letter-spacing: .1em;
			font-size: 14px;
			text-transform: uppercase;
			display: inline-flex;
			margin: 0 20px;
		 }

		 &:hover {
			stroke-dashoffset: 0;
		 }
	  }

	  @media (max-width: 991px) {
		 padding-left: 30px;
		 padding-right: 30px;
	  }

	  @media (max-width: 1024px) {
		 a span{
			display: none;
		 }
	  }

	  @media (max-width: 575px) {
		 top: 90%;
	  }
   }

   .ico-link {
	  position: absolute;
	  color: $lightColor;
	  bottom: 50px;
	  left: 50%;
	  transform: translateX(-50%);
	  font-size: 64px;
	  z-index: 10;
	  animation: linear 3s infinite floating;

	  @media (max-width: 991px) {
		 font-size: 40px;
		 bottom: 30px;
	  }
   }

   @keyframes floating {
	  0%, 100% {
		 transform: translateY(-15%) translateX(-50%);
	  }

	  50% {
		 transform: translateY(15%) translateX(-50%);
	  }
   }

   & > img {
	  width: 100%;
   }

   h2 {
	  margin: 0;
	  line-height: 1;
   }

   h3 {
	  margin: 0;
	  line-height: 1;
   }

   h4 {
	  margin: 0;
   }

   h5 {
	  margin: 0;
   }

   p {
	  margin: 0;
   }

   .bigger {
	  line-height: 1;
   }

   h2.bigger {
	  font-size: 60px;
   }

   h3.bigger {
	  font-size: 50px;
   }

   h4.bigger {
	  font-size: 40px;
   }

   h5.bigger {
	  font-size: 30px;
   }

   p.bigger {
	  font-size: 16px;
   }

   @media (min-width: 767px) {
	  h2.bigger {
		 font-size: 110px;
	  }
	  h3.bigger {
		 font-size: 90px;
	  }
	  h4.bigger {
		 font-size: 60px;
	  }
	  h5.bigger {
		 font-size: 50px;
	  }
   }

   @media (min-width: 1200px) {
	  h2.bigger {
		 font-size: 200px;
	  }
	  h3.bigger {
		 font-size: 120px;
	  }
	  h4.bigger {
		 font-size: 70px;
	  }
	  h5.bigger {
		 font-size: 60px;
	  }
	  p.bigger {
		 font-size: 20px;
	  }
   }

   @media (min-width: 1600px) {
	  h2.bigger {
		 font-size: 480px;
		 line-height: .7;
	  }
	  h3.bigger {
		 font-size: 240px;
		 line-height: .7;
	  }
   }


   &.no-dots {
	  .flex-control-nav {
		 display: none;
	  }
   }

   &.no-nav {
	  .flex-direction-nav {
		 display: none;
	  }
   }

   &.with-abs-header {
	  .intro_layers {
		 margin-top: 100px;
		 @media (max-width: 1199px) {
			margin-top: 80px;
		 }
	  }
   }

   .flex-direction-nav {
	  height: 0;
	  @media (max-width: 575px) {
		 display: none;
	  }

	  a {
		 width: 60px;
		 height: 60px;
	  }
   }

   .flex-direction-nav .flex-prev {
	  left: 5%;
	  //opacity: 1;
	  //&::before {
	  // content: "";
	  // display: inline-block;
	  // width: 30px;
	  // height: 50px;
	  // -webkit-mask: url(../img/arrow.png) no-repeat center;
	  // background-color: $lightColor;
	  //}
   }

   .flex-direction-nav .flex-next {
	  right: 5%;
	  //opacity: 1;
	  //&::before {
	  // content: "";
	  // left: 0;
	  // display: inline-block;
	  // width: 30px;
	  // height: 50px;
	  // -webkit-mask: url(../img/arrow.png) no-repeat center;
	  // background-color: $lightColor;
	  // position: relative;
	  // transform: rotate(180deg);
	  //}
   }

   .flexslider {
	  //making every slide same height (stretch) for nice background on every slide
	  $heights: 500 550 600 650 700 750 800 850 900 950 1000 1050 1100;
	  @each $height in $heights {
		 .slider_height_desktop_#{$height} {
			&.slides li {
			   min-height: #{$height}px;
			}
		 }
	  }
	  @each $height in $heights {
		 .slider_height_laptop_#{$height} {
			&.slides li {
			   @media (max-width: 1399px) {
				  min-height: #{$height}px;
			   }
			}
		 }
	  }
	  @each $height in $heights {
		 .slider_height_tablet_#{$height} {
			&.slides li {
			   @media (max-width: 991px) {
				  min-height: #{$height}px;
			   }
			}
		 }
	  }
	  @each $height in $heights {
		 .slider_height_mobile_#{$height} {
			&.slides li {
			   @media (max-width: 767px) {
				  min-height: #{$height}px;
			   }
			}
		 }
	  }

	  @each $height in $heights {
		 .slider_height_mobile_small_#{$height} {
			&.slides li {
			   @media (max-width: 575px) {
				  min-height: #{$height}px;
			   }
			}
		 }
	  }

	  &[data-dots="false"] {
		 .nav-container {
			display: none;
		 }
	  }

	  &[data-nav="false"] {
		 .flex-direction-nav {
			display: none;
		 }
	  }

	  .slides {
		 display: flex;

		 li {
			min-height: 800px;

			> img {
			   display: none;
			}

			@media (max-width: 1199px) {
			   min-height: 600px;
			}
		 }
	  }

	  //ie bugfix with page_slider overlap
	  &:before,
	  &:after {
		 content: '';
		 display: block;
		 clear: both;
	  }

	  .intro_layer img {
		 width: auto;
		 display: inline-block;
	  }

	  //uncomment if you need a shadow on your slider
	  &.slider-shadow {
		 padding-bottom: 50px;

		 &:before,
		 &:after {
			content: "";
			position: absolute;
			background: transparent;
			z-index: 1;
			bottom: 65px;
			left: 10px;
			width: 50%;
			height: 20%;
			max-width: 400px;
			box-shadow: 0 25px 20px rgba(0, 0, 0, 0.3);
			transform: rotate(-3deg);
		 }

		 &:after {
			right: 10px;
			left: auto;
			transform: rotate(3deg);
		 }
	  }
   }

   .intro_layers_wrapper {
	  display: flex;
   }

   .intro_after_featured_word {
	  font-size: 12px;
	  font-weight: 700;
	  text-transform: uppercase;
	  @media(max-width: 400px) {
		 display: none;
	  }
   }

   .row {
	  height: 100%;
   }

   .intro_layers {
	  padding: 20px 0;
	  position: relative;
	  @media (max-width: 575px) {
		 padding: 0;
	  }
   }

   //dots - horizontal
   .flex-control-nav {
	  position: absolute;
	  width: auto;
	  left: 50%;
	  bottom: 50px;
	  transform: translateX(-50%);
	  display: flex;
	  justify-content: center;
	  list-style: none outside none;

	  li {
		 display: block;
		 padding: 0;
		 list-style: none;
		 line-height: 1;

		 a {
			border: none;
			opacity: 1;
			width: 6px;
			height: 6px;
			margin: 12px;
			background-color: $lightColor;
			position: relative;

			&::before {
			   content: '';
			   position: absolute;
			   top: 50%;
			   left: 50%;
			   transform: translate(-50%, -50%);
			   display: inline-block;
			   width: 6px;
			   height: 6px;
			   border: 1px solid transparent;
			   border-radius: 50%;
			   transition: all .3s ease-in-out;
			}

			&:hover,
			&.flex-active {
			   &::before {
				  width: 26px;
				  height: 26px;
				  border-color: $lightColor;
			   }
			}
		 }
	  }

	  @media (max-width: 767px) {
		 bottom: 15px;
	  }
   }

   &.dot_1 {
	  .flex-control-nav {
		 display: inline-flex;
		 align-items: center;
		 justify-content: center;
		 flex-direction: column;
		 counter-reset: li;

		 li {
			a {
			   width: auto;
			   height: auto;
			   background-color: transparent;

			   &::before {
				  width: 30px;
				  height: 2px;
				  transform: translateY(-50%);
				  left: auto;
				  right: calc(100% + 10px);
				  border: unset;
				  border-radius: 0;
			   }

			   &::after {
				  content: counter(li, decimal-leading-zero) ".";
				  counter-increment: li;
				  font-size: 16px;
				  width: auto;
				  height: auto;
				  color: $lightColor;
			   }

			   &.flex-active,
			   &:hover {
				  &::before {
					 background-color: $lightColor;
				  }
			   }
			}
		 }

		 @media (min-width: 992px) {
			left: auto;
			right: 50px;
			bottom: auto;
			top: 50%;
			transform: translateY(-50%);
		 }
		 @media (max-width: 991px) {
			flex-direction: row;
			li {
			   a {
				  &::before {
					 top: auto;
					 width: 2px;
					 height: 30px;
					 transform: translateX(-50%);
					 bottom: calc(100% + 10px);
					 left: 50%;
				  }
			   }
			}
		 }
	  }

	  .nav-container {
		 position: static;
	  }
   }

   &.dot_2 {
	  .flex-control-nav {
		 display: inline-flex;
		 align-items: center;
		 justify-content: center;
		 flex-direction: column;
		 counter-reset: li;

		 li {
			a {
			   width: auto;
			   height: auto;
			   background-color: transparent;

			   &::before {
				  content: '';
				  width: 15px;
				  height: 15px;
				  display: inline-block !important;
				  background-color: transparent;
				  position: absolute;
				  left: 50%;
				  top: -50%;
				  z-index: -1;
				  border: none;
				  border-radius: 0;
				  transform: unset;
			   }

			   &::after {
				  content: counter(li, decimal-leading-zero);
				  counter-increment: li;
				  font-size: 16px;
				  width: auto;
				  height: auto;
				  color: $fontColor;
				  transition: all .3s ease-in-out;
			   }

			   &.flex-active,
			   &:hover {
				  &::before {
					 background-color: $colorMain;
				  }

				  &::after {
					 color: $darkColor;
				  }
			   }
			}
		 }


		 @media (min-width: 1400px) {
			left: 50px;
			bottom: auto;
			top: 50%;
			transform: translateY(-50%);
		 }
		 @media (max-width: 1399px) {
			flex-direction: row;
			bottom: 10px;
		 }
	  }

	  .nav-container {
		 position: static;
	  }
   }

   //lg and bigger layout
   .cs,
   .ds {
	  background-color: transparent;
   }

   //description alignment
   //for intro with testimonials,
   //for static intro
   //.flexslider prefix removed
   .container-fluid,
   .container,
   img + .container {
	  position: absolute;
	  top: 0;
	  bottom: 0;
	  left: 0;
	  right: 0;
	  margin-left: auto;
	  margin-right: auto;

   }

   .container {
	  @media (min-width: 1200px) and (max-width: 1299px) {
		 max-width: none;
	  }
   }

   [class*="col-"] {
	  position: static;
	  min-height: 0;
	  margin-top: 0;
	  margin-bottom: 0;
   }

   // center alignment for slide description
   .intro_layers_wrapper {
	  position: absolute;
	  top: 0;
	  bottom: 0;
	  right: 15px;
	  left: 15px;
	  height: 100%;
	  z-index: 5;

	  @media (min-width: 600px) {
		 .boxed & {
			padding-left: 60px;
			padding-right: 60px;
		 }
	  }
   }

   .intro_text_top .intro_layers {
	  justify-content: flex-start;
   }

   .intro_text_bottom .intro_layers {
	  justify-content: flex-end;
   }


   .d-inline-block {
	  width: 100%;
   }

   .slide-video {
	  width: 100%;
	  z-index: -1;
	  height: 100%;
	  object-fit: cover;
	  position: absolute;
	  left: 50%;
	  top: 50%;
	  transform: translate(-50%, -50%);
   }

   .button-layer {
	  div {
		 display: inline-flex;
	  }

	  a,
	  .btn {
		 margin-right: 15px;
		 margin-bottom: 15px;
		 @media (min-width: 1200px) {
			margin-right: 30px;
		 }
	  }
   }

   .photoswipe-link-button-spec {
	  &.absolute-btn {
		 @media (min-width: 1200px) {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		 }
	  }
   }

   .row {
	  display: flex;
	  align-items: center;
   }

   .intro_text_top {
	  align-items: flex-start;
   }

   .intro_text_bottom {
	  align-items: flex-end;
   }

   .photoswipe-link {
	  display: inline-flex;
	  align-items: center;
	  flex-direction: row-reverse;
	  margin: 0 !important;

	  &-label {
		 display: inline-flex;
		 align-items: center;
		 margin-right: 30px;
		 font-size: 18px;
		 font-weight: 500;
		 transition: all .3s ease-in-out;

		 @media (max-width: 575px) {
			display: none;
		 }
	  }

	  &:hover {
		 .photoswipe-link-label {
			opacity: 1;
		 }
	  }
   }

   br {
	  @media (max-width: 767px) {
		 display: none;
	  }
   }

   .line-left span {
	  position: relative;

	  &::before {
		 content: '';
		 width: 2px;
		 height: .8em;
		 display: inline-block;
		 background-color: $colorMain;
		 margin-right: 10px;
	  }
   }

   .rectangle span {
	  position: relative;
	  z-index: 1;
	  letter-spacing: .2em;

	  &::before {
		 content: '';
		 width: 28px;
		 height: 15px;
		 display: inline-block !important;
		 background-color: $colorMain;
		 position: absolute;
		 left: -9px;
		 top: -5px;
		 z-index: -1;
	  }
   }

   .with-under-text span {
	  @media (min-width: 992px) {
		 position: relative;
		 display: inline-block;
		 &::before {
			word-break: break-word;
			content: attr(data-content);
			-webkit-text-stroke-width: 1px;
			color: transparent;
			-webkit-text-stroke-color: rgba(255, 255, 255, .5);
			-webkit-text-fill-color: transparent;
			position: absolute;
			top: 4px;
			left: 4px;
			width: 100%;
			height: 100%;
			z-index: -1;
		 }
	  }
   }

   .light_version {
	  @media (min-width: 1600px) {
		 position: absolute;
		 right: 50px;
		 bottom: 80px;

		 &.v2 {
			display: none;
		 }
	  }
	  @media (max-width: 1599px) {
		 display: none;
		 &.v2 {
			margin-top: 40px;
			display: inline-flex;
			width: 100%;
			align-items: flex-start;
		 }
	  }

	  @media (max-width: 991px) {
		 &.v2 {
			flex-direction: row;

			& > * {
			   margin: 0;

			   &:first-child {
				  margin-right: 30px;
			   }
			}
		 }
	  }
   }

   .social-icons {
	  a {
		 &:hover {
			position: relative;
			transform: scale(1.4);
		 }
	  }
   }

   @media (max-width: 1599px) {
	  .social-icons-layer {
		 display: none;
	  }
	  .slider-social {
		 margin-top: 20px;
		 display: inline-flex;
		 align-items: center;

		 .text {
			font-size: 12px;
			text-transform: uppercase;
			font-weight: 500;
			margin: 0;
			padding-right: 80px;
			letter-spacing: .2em;
			position: relative;

			&::after {
			   content: '';
			   position: absolute;
			   width: 40px;
			   height: 1px;
			   background-color: $lightColor;
			   display: inline-block;
			   right: 20px;
			   top: 50%;
			}

			@media (max-width: 575px) {
			   display: none;
			}
		 }
	  }
   }

   @media (min-width: 1600px) {
	  .slider-social {
		 display: none;
	  }
	  .social-icons-layer {
		 position: absolute;
		 left: 50px;
		 top: 50%;
		 transform: translateY(-50%);
		 display: flex;
		 flex-direction: column;

		 &.icon-social-right {
			left: auto;
			right: 50px;
		 }

		 .text {
			writing-mode: tb;
			position: relative;
			transform: rotate(180deg);
			font-size: 12px;
			text-transform: uppercase;
			font-weight: 500;
			margin: 0;
			padding-bottom: 80px;
			letter-spacing: .2em;

			&::before {
			   content: '';
			   position: absolute;
			   width: 1px;
			   height: 40px;
			   display: inline-block;
			   background-color: $lightColor;
			   bottom: 20px;
			   left: 50%;
			}
		 }

		 .social-icons {
			display: inline-flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			font-size: 14px;

			a {
			   margin-left: 0;
			   margin-right: 0;

			   &:not(:last-child) {
				  margin-bottom: 20px;
			   }
			}
		 }
	  }
   }


   .ds {
	  a.social-icon:not(:hover) {
		 color: $lightColor;
	  }
   }

   .image_absolute_slider {
	  display: inline-block;
	  position: absolute;
	  top: 0;
	  left: 0;
	  z-index: 0;

	  &.image-bottom {
		 top: auto;
		 bottom: 0;
	  }

	  &.image-right {
		 left: auto;
		 right: 0;
	  }

	  img {
		 width: auto !important;
		 height: auto;
		 display: inline-block !important;
	  }
   }

   &.page_slider_2 {
	  .row {
		 flex-wrap: wrap;
		 @media (min-width: 767px) {
			flex-direction: row-reverse;
		 }
	  }

	  .photoswipe-link {
		 &::before {
			color: $darkColor;
			border-color: rgba($darkColor, .2);
		 }

		 &:hover::before {
			border-color: $darkColor;
		 }
	  }

	  .photoswipe-link-label {
		 &:not(:hover) {
			color: $darkColor;
		 }

		 &::before {
			background-color: rgba($darkColor, .2);
		 }
	  }
   }

   .img-bulb {
	  position: relative;
	  z-index: 1;

	  &::before {
		 content: "";
		 display: inline-block;
		 height: 100%;
		 width: 95%;
		 position: absolute;
		 right: -10%;
		 top: 3%;
		 z-index: -1;
		 background-color: $colorMain;
		 border-radius: 58% 42% 26% 74% / 74% 68% 32% 26%;
		 animation: bulb2 17s linear infinite;
		 @media (max-width: 575px) {
			right: 0;
		 }
	  }
   }
}

.slider-form {
   background-color: $lightColor;

   &-header {
	  background-color: $colorMain;
	  padding: 45px 80px;

	  @media (max-width: 1440px) {
		 padding-left: 50px;
		 padding-right: 50px;
	  }

	  @media (max-width: 575px) {
		 padding: 30px;
	  }
   }

   &-content {
	  padding: 80px;

	  @media (max-width: 1440px) {
		 padding: 50px;
	  }

	  @media (max-width: 575px) {
		 padding-left: 30px;
		 padding-right: 30px;
	  }
   }

   .btn:hover {
	  opacity: 1;
   }
}

.ds {
   a.fa {
	  &:hover {
		 color: $lightColor;
	  }
   }

   .slider-form {
	  h1,
	  h2,
	  h3,
	  h4,
	  h5,
	  h6 {
		 color: $darkColor;
	  }
   }

   .form-control:focus {
	  border-color: $darkColor;
   }

   .form-control:-moz-placeholder {
	  color: $fontColor;
	  opacity: 1;
   }

   .form-control::-moz-placeholder {
	  color: $fontColor;
	  opacity: 1;
   }

   .form-control:-ms-input-placeholder {
	  color: $fontColor;
   }

   .form-control::-webkit-input-placeholder {
	  color: $fontColor;
   }

   .form-control {
	  color: $fontColor;
   }

   .btn-darkgrey {
	  background-color: $darkgreyColor;
	  border-color: $darkgreyColor;
	  color: $lightColor;

	  &:hover {
		 opacity: 1;
	  }
   }
}


.shortcode-video {
   display: flex;

   .item-media {
	  flex-grow: 1;
   }

   .custom-title {
	  writing-mode: vertical-lr;
	  text-align: center;
	  padding: 10px 25px;
	  background-color: $lightColor;
	  text-transform: uppercase;
	  letter-spacing: .1em;
	  font-size: 14px;
	  font-weight: 600;

	  span {
		 transform: rotate(180deg);
		 position: relative;
		 display: inherit;
	  }
   }

   .embed-placeholder,
   .photoswipe-link-button {
	  width: 64px;
	  height: 64px;

	  &::before {
		 width: 64px;
		 height: 64px;
	  }

	  &::after {
		 font-size: 14px;
	  }
   }
}

.intro_section {
   position: relative;
}

.intro_section + .slider-video .shortcode-video {
   @media (min-width: 992px) {
	  position: absolute !important;
	  overflow: visible !important;
	  bottom: 0;
	  right: 0;
	  max-width: 400px;
   }

   @media (min-width: 1200px) {
	  max-width: 475px;
   }
}
