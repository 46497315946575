/*search modal*/
#search_modal {

   padding: 0 !important;

   .modal-dialog {
	  max-width: none;
	  margin: 0;
   }

   .widget {
	  padding: 20px 0;
	  background-color: $lightColor;
   }

   .search-form {
	  margin: 0 auto;
	  max-width: 960px;
	  padding: 0;

	  @media (max-width: 1199px) {
		 margin: 0 80px 0 15px;
	  }
   }

   a {
	  @include small-text();
	  color: $lightColor;

	  &:hover {
		 color: $colorMain;
		 opacity: 1;
	  }
   }

   .searchform-respond {
	  width: 300px;
	  margin: 20px auto;
	  color: $lightColor;
   }

   .form-group {
	  margin-bottom: 0;
   }

   .form-control {
	  //border: none;
	  background-color: transparent;
	  color: $darkgreyColor;
	  width: 100%;
	  max-width: none;
	  font-size: 28px;
	  font-style: italic;
   }

   .form-control:-moz-placeholder {
	  color: $fontColor;
	  opacity: 1;
   }

   .form-control::-moz-placeholder {
	  color: $fontColor;
	  opacity: 1;
   }

   .form-control:-ms-input-placeholder {
	  color: $fontColor;
   }

   .form-control::-webkit-input-placeholder {
	  color: $fontColor;
   }

   .btn {
	  color: $darkgreyColor;
	  width: auto;
	  font-size: 0;
	  min-width: auto;

	  &:before {
		 font-size: 18px;
		 color: $darkgreyColor;

	  }
   }

   .close {
	  text-align: center;
	  line-height: 60px;
	  position: absolute;
	  top: 14px;
	  right: 0;
	  min-width: 60px;
	  color: $darkgreyColor;
	  text-shadow: none;
	  opacity: 1;
	  z-index: 2;
	  font-size: 30px;
	  border: none;
	  padding: 0;

	  &:hover{
		 color: $lightColor;
	  }
   }

   .active-side-header & {
	  right: $sideHeaderWidth;
   }
}

.search_modal_button {
   display: inline-block;
   font-size: 14px;
   line-height: 1;
   padding: 0 0 0 10px;
}