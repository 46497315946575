/* widget_tag_cloud */
.widget_tag_cloud {
  margin-bottom: -20px;

  a {
    border: 1px solid $fontColor;
    border-radius: 0;
    padding: 12px 22px;
    margin: 0 20px 20px 0;
    text-align: center;
    display: inline-block;
    font-size: 14px !important;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: .1em;
    line-height: 1;
    color: $darkgreyColor;

    &:hover {
      background-color: $darkgreyColor;
      border-color: $darkgreyColor;
      color: $lightColor;
    }
  }
}

.ds {
  .widget_tag_cloud {
    a:not(.btn) {
      color: $lightColor;

      &:hover {
        color: $darkColor;
      }
    }
  }
}