/* forms */
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="number"],
textarea,
select,
.form-control {
   height: 50px;
   border: {
	  top: 0;
	  left: 0;
	  right: 0;
	  bottom: 1px solid $borderColor;
   };
   background-color: transparent;
   border-radius: 0;
   color: $darkColor;
   padding: 5px 0;
   font-size: 16px;
   width: 100%;
}

.form-control{
   position: relative;
}

.border-animate {
   position: absolute;
   bottom: 0;
   left: 0;
   width: 100%;
   height: 1px;
   background-color: $darkgreyColor;
   transition: transform .4s cubic-bezier(.85,.26,.17,1);
   transform: scaleX(0);
   transform-origin: 100% 50%;
}

.ds{
   .border-animate{
      background-color: rgba($lightColor, .5);
   }
}

.form-control:focus ~ .border-animate{
   transform-origin: 0 50%;
   transform: scaleX(1);
}

input[disabled] {
   opacity: .4;
   cursor: not-allowed;
}

input[type="submit"],
button {
   cursor: pointer;
}

input[type="submit"],
button:not([class*="owl"]):not(.btn) {
   cursor: pointer;
   @include small-text();
   padding: 19px 36px;
   background: transparent;
   border: 1px solid $darkgreyColor;
   color: $darkgreyColor;
   border-radius: 0;
   transition: all 0.2s ease-in-out;

   &:hover {
	  color: $lightColor;
      background-color: $darkgreyColor;
   }

   .ds &:not([class]), .cs &:not([class]) {
	  border-color: $lightColor;
	  color: $lightColor;

	  &:hover {

	  }
   }
}

textarea,
textarea.form-control {
   padding-top: 10px;
   height: auto;
   min-height: 130px;
}

.form-group {
   position: relative;

   [class*='col-'] > & {
	  margin-bottom: 0;
   }

   [class*='col-'] > & + & {
	  margin-top: 30px;
   }
}

.has-placeholder {
   label {
	  display: none;
   }
}

/* select styling */
select[multiple] {
   height: auto;
}

select {
   -webkit-appearance: none;
   -moz-appearance: none;
}

select option,
.form-control option {
   color: $darkgreyColor;
   background-color: $lightColor;
}

.form-control-unstyled {
   border: none;
   background-color: transparent;
}

.form-control, .form-control:focus {
   -webkit-box-shadow: none;
   box-shadow: none;
}

.form-control.invalid {
   -webkit-box-shadow: 0 0 0 2px $colorMain;
   box-shadow: 0 0 0 2px $colorMain;
}

.form-control:focus {
   border-color: darken($borderColor, 15%);
   background-color: transparent;
}

.form-control:-moz-placeholder {
   color: $fontColor;
   opacity: 1;
}

.form-control::-moz-placeholder {
   color: $fontColor;
   opacity: 1;
}

.form-control:-ms-input-placeholder {
   color: $fontColor;
}

.form-control::-webkit-input-placeholder {
   color: $fontColor;
}

.form-control:focus:-moz-placeholder {
   color: transparent;
}

.form-control:focus::-moz-placeholder {
   color: transparent;
}

.form-control:focus:-ms-input-placeholder {
   color: transparent;
}

.form-control:focus::-webkit-input-placeholder {
   color: transparent;
}

/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

/*icons for forms */
form label + [class*="fa-"],
form label + [class*="ico-"] {
   position: absolute;
   font-size: 16px;
   left: 0;
   top: 60px;
   width: 1em;
   text-align: center;
}

//.has-placeholder class has .form-group elements
form .has-placeholder label + [class*="fa-"],
form .has-placeholder label + [class*="ico-"] {
   top: 18px;
}

form label + [class*="fa-"] + .form-control,
form label + [class*="ico-"] + .form-control {
   padding-left: 25px;
}

label {
   color: $fontColor;
   font-size: 14px;

   .checkbox &,
   .radio & {
	  font-weight: inherit;
   }

   .checkbox &[for],
   .radio &[for] {
	  font-weight: inherit;
   }
}

.checkbox,
.radio {
   label[for] + input[type=checkbox],
   label[for] + input[type=radio] {
	  left: 20px;
   }
}

input[type=checkbox],
input[type=radio] {
   margin-top: 8px;
   display: none;
}

//for wordpress widgets wrapper
.form-wrapper {
   &.hero-bg,
   &.muted-bg,
   &.cs,
   &.ds,
   &.ls {
	  padding: 35px;
   }
}

//styled checkboxes and radio
.has-error .form-control {
   border-color: $colorMain;
}

.has-error label,
.has-error .help-block {
   color: $colorMain;
}

/*styled checkbox */
.checkbox {
   input[type="checkbox"] {
	  display: none;
   }

   label + input[type="checkbox"],
   label > input[type="checkbox"] {
	  display: inline-block;
   }
}

input[type="checkbox"] + label {
   padding-left: 40px;
   line-height: 1.4;
}

input[type="checkbox"] + label > a {
   color: $darkgreyColor;
}

input[type="checkbox"] + label:before {
   content: "";
   display: inline-block;
   vertical-align: middle;
   margin: 0;
   text-align: center;
   padding-right: 0.1em;
   width: 20px;
   height: 20px;
   line-height: 20px;
   font-size: 16px;
   border: 1px solid $borderColor;
   position: absolute;
   left: 0;
   top: 50%;
   transform: translateY(-50%);
   font-family: "icomoon";
}


input[type="checkbox"]:checked + label:before {
   content: "\e923";
   color: $darkgreyColor;
}

.ds{
   input[type="checkbox"] + label:before {
      border-color: $fontColor;
   }
   input[type="checkbox"]:checked + label:before {
      color: $lightColor;
   }
}

input[type="checkbox"]:disabled + label:before {
   background-color: $borderColor;
}

/*styled radio*/
.radio {
   input[type="radio"] {
	  display: none;
   }

   label + input[type="radio"],
   label > input[type="radio"] {
	  display: inline-block;
   }
}

input[type="radio"] + label {
   padding-left: 0;
}

input[type="radio"] + label > a {
   color: #999999;
}

input[type="radio"] + label:before {
   content: "";
   display: inline-block;
   vertical-align: middle;
   margin-right: 10px;
   margin-bottom: 2px;
   width: 16px;
   height: 16px;
   border-radius: 50%;
   border: 2px solid $borderColor;
}

input[type="radio"]:checked + label:before {
   padding: 3px;
   background-color: $darkgreyColor;
   background-clip: content-box;
}

input[type="radio"]:disabled + label:before {
   background-color: $borderColor;
}

.select_container {
   position: relative;

   select {
	  color: $fontColor;
	  padding: 5px 0;
   }

   &::after {
	  content: "\e906";
	  font-family: "icomoon", sans-serif;
	  position: absolute;
	  top: 50%;
	  transform: translateY(-50%);
	  right: 0;
	  color: $darkColor;
	  font-size: 14px;
	  pointer-events: none;
   }
}
