/*
**plugins
*/
@import 'plugins/owl';
@import 'plugins/flexslider';
@import 'plugins/photoswipe';
@import 'plugins/piechart';
@import 'plugins/timetable';
@import 'plugins/totop';
@import 'plugins/countdown';
@import 'plugins/scroll_sidebar';