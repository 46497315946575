/*
** Light Background Section - .ls
*/
.ls {
  background-color: $lightColor;
  color: $fontColor;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $darkgreyColor;
    a {
      color: inherit;
      &:hover {
        //color: $colorMain;
        opacity: 0.8;
      }
    }
  }

  //buttons
  .btn-maincolor:hover,
  .btn-maincolor2:hover,
  .btn-maincolor3:hover,
  .btn-white:hover,
  .btn-outline-maincolor:hover,
  .btn-outline-maincolor2:hover,
  .btn-outline-maincolor3:hover,
  .btn-outline-darkgrey:hover{
    background-color: $darkgreyColor;
    &::after{
      color: $lightColor;
    }
  }

  .btn-maincolor,
  .btn-maincolor2,
  .btn-maincolor3{
    color: $lightColor;
  }

  .btn-white{
    background-color: $greyColor;
  }

  .btn-darkgrey:hover{
    background-color: $greyColor;
    color: $darkgreyColor;
  }

  .btn-outline-maincolor:hover,
  .btn-outline-maincolor2:hover,
  .btn-outline-maincolor3:hover{
    border-color: $darkgreyColor;
  }

  .links-maincolor a:hover {
    opacity: 0.7;
  }
}

/*
** Grey Background Section - light section, muted section - .ls.ms
*/
.ls.ms {
  background-color: $greyColor;
  color: darken($fontColor, 7%);

  .btn-white{
    background-color: $lightColor;
    &:hover{
      background-color: $darkgreyColor;
    }
  }

  .btn-darkgrey{
    &:hover{
      background-color: $lightColor;
    }
  }

  .links-grey a {
    color: darken($fontColor, 7%);
  }

  .hero-bg {
    background-color: $lightColor;
    hr {
      background-color: $borderColor;
    }
  }

  hr {
    background-color: $lightColor;
  }

  //forms
  input[type="text"],
  input[type="email"],
  input[type="url"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="number"],
  textarea,
  select,
  .form-control {

  }

  // .ls:not(.ms),
  .hero-bg {
    input[type="text"],
    input[type="email"],
    input[type="url"],
    input[type="password"],
    input[type="search"],
    input[type="tel"],
    input[type="number"],
    textarea,
    select,
    .form-control {
      border-color: $borderColor;
    }
  }
  td,
  th,
  .bordered {
    border-color: darken($borderColor, 5%);
  }

  .btn-secondary {
    border-color: $lightColor;
    &:hover {
      border-color: darken($greyColor, 6%);
      background-color: darken($greyColor, 6%);
    }
  }

}