.pricing-plan {
   display: flex;
   flex-direction: column;

   @media (min-width: 768px) {
	  height: 100%;
   }

   .pricing-plan-wrap {
	  padding: 80px;
	  position: relative;
   }

   .plan-name {
	  margin-top: 45px;

	  .subtitle {
		 color: $fontColor;
		 letter-spacing: .1em;
		 font-size: 14px;
		 text-transform: uppercase;
		 margin-bottom: 0;
		 font-weight: 600;
	  }

	  .title {
		 margin-top: 0;
	  }
   }

   .price-wrap {
	  position: absolute;
	  right: 80px;
	  top: 80px;
	  -webkit-text-stroke-color: rgba($darkColor, .1);
	  line-height: 0.75;
	  font-weight: 800;

	  .plan-sign {
		 font-size: 60px;
		 position: relative;
		 display: inline-block;
		 transform: translateY(-160%);
	  }

	  .plan-price {
		 font-size: 160px;
	  }
   }

   .plan-features {
	  margin-top: 50px;

	  .list-styled {
		 li {
			&:first-child {
			   padding-top: 0;
			}

			&:last-child {
			   padding-bottom: 0;
			}
		 }
	  }
   }

   .plan-button {
	  margin-top: auto;

	  .btn {
		 text-align: left;
		 margin-bottom: 0;
		 padding-left: 80px;

		 &::before{
			content: "\e901";
			font-family: 'icomoon';
			font-size: 5px;
			margin-right: 15px;
		 }

		 &:hover{
			&::after{
			   left: 115px;
			   transform: translateY(-50%);

			   @media (max-width: 1440px) {
				  left: 70px;
			   }
			}
		 }
	  }
   }

   &.plan-featured {
	  border: 10px solid $colorMain;
	  transform: translateY(-10px);

	  .plan-button {
		 top: 48px;
	  }
   }

   @media (max-width: 1440px) {
	  .pricing-plan-wrap {
		 padding: 40px;
	  }

	  .plan-name {
		 margin-top: 5px;
	  }

	  .price-wrap {
		 right: 40px;
		 top: 40px;

		 .plan-sign {
			font-size: 40px;
		 }

		 .plan-price {
			font-size: 106px;
		 }
	  }

	  .plan-features {
		 margin-top: 30px;
	  }

	  .plan-button {
		 .btn {
			padding-left: 40px;
		 }
	  }
   }

   @media (max-width: 1199px) {
	  .pricing-plan-wrap {

	  }

	  .plan-name {
		 margin-top: 0;

		 .title {
			font-size: 30px;
		 }
	  }

	  .price-wrap {
		 position: static;
		 margin-top: 30px;

		 .plan-sign {

		 }

		 .plan-price {

		 }
	  }

	  .plan-features {

	  }

	  .plan-button {
		 .btn {

		 }
	  }
   }
}

