/* 
** toTop
*/
#toTop {
   bottom: 30px;
   right: 0;
   display: none;
   width: 50px;
   height: 50px;
   overflow: hidden;
   position: fixed;
   text-decoration: none;
   //fix for side push header
   z-index: 1000;
   font-size: 0;
   color: transparent;
   opacity: 1;
   mix-blend-mode: exclusion;

   @media (min-width: 1200px) {
	  right: 80px;
	  bottom: 120px;
   }
}

#toTop:after {
   content: "\e921";
   color: $lightColor;
   font-size: 16px;
   line-height: 60px;
   font-family: 'icomoon';
   text-align: center;
   display: block;
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   transform: rotate(90deg);
   //transition: all 0.2s linear;
}

#toTopHover, #toTop:hover {
   transform: translateY(20%);
   opacity: 1;
}

#toTop:hover:after {
   color: $lightColor;
}

#toTop:active, #toTop:focus {
   outline: medium none;
}

.modal-open #toTop {
   visibility: hidden;
}

.toTopHeader {
   &#toTop {
	  @media (min-width: 1680px) {
		 display: block !important;
		 right: auto;
		 left: 8px;
		 bottom: 40px;

		 &[style="display: none;"] {
			&:after {
			   content: '\e924';
			   transform: unset;
			   font-size: 40px;
			}
		 }
	  }
	  @media (min-width: 1800px) {
		 left: 40px;
	  }
   }
}