/*
** Icon Box
*/
//styled icon
//used in icon box and inline icon
.icon-styled {
   text-align: center;
   display: inline-flex;
   line-height: 1;

   //icon style
   i {
      width: auto;
   }

   //bordered icon, button icon and icon with background
   &[class*='bg-'],
   &[class*='btn-'],
   &.bordered,
   &.icon-bordered {
      width: 2em;
      height: 2em;
      box-sizing: content-box;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      .text-center & {
         margin-left: auto;
         margin-right: auto;
      }

      i {
         line-height: 2em;
      }

      border-width: 1px;
      border-style: solid;

      & + p {
         margin-top: 12px;
      }
   }

   &.icon-bordered:not([class*="color-"]) {
      border-color: $borderColor;
   }

   &[class*='bg-'] {
      border-color: transparent;
   }

   &.icon-top {
      align-self: flex-start;
      line-height: inherit;
   }

   //side icon box
   //styled icon in .media and in icon-inline component
   .media > &:first-child {
      flex-shrink: 0;
   }

   .media > &:last-child {
      flex-shrink: 0;
   }

   .icon-inline > &:first-child {
      flex-shrink: 0;
      margin-right: 20px;
   }

   .icon-inline > &:last-child {
      flex-shrink: 0;
      margin-left: 20px;
   }
}

//inline styled icon
.icon-inline {
   display: flex;
   align-items: center;

   .text-center & {
      justify-content: center;
   }

   & > * {
      margin-top: 0;
      margin-bottom: 0;
      margin-right: 4px;
   }
}

//styled icon in box
.icon-box {
   //padding for icon box with borders and background
   &.ls,
   &.ds,
   &.cs,
   &.bordered,
   &.hero-bg,
   &.muted-bg,
   &.box-shadow {
      padding: 80px;

      @media (max-width: 1400px) {
         padding: 40px;
      }
   }

   //icon boxes in bordered columns
   .c-bordered & {
      padding: 70px 30px 30px;
      //if P element is a last child - adding bottom margin
      p:last-child {
         margin-bottom: 20px;
      }
   }

   //featured box in bordered columns
   @media (min-width: 768px) {
      &.featured-box {
         position: relative;
         z-index: 2;
         margin: -6px -10px;
         //padding top and padding bottom more on negative top and bottom margins 70+6,
         //12px - 6 top and 6 bottom
         height: calc(100% + 12px);
         padding-top: 76px;
         padding-bottom: 36px;
      }
   }

   &.pattern1 {
      background-image: url('../img/patterns/pattern13.png');
      background-repeat: no-repeat;
      background-size: cover;
   }

   &.pattern2 {
      background-image: url('../img/patterns/pattern14.png');
      background-repeat: no-repeat;
      background-size: cover;
   }

   &.pattern3 {
      background-image: url('../img/patterns/pattern15.png');
      background-repeat: no-repeat;
      background-size: cover;
   }

   .text-special {
      position: absolute;
      opacity: .1;
      right: 0;
      bottom: 20%;
      font-size: 160px;
      font-weight: 800;
      line-height: 0.75;

      @media (max-width: 1199px) {
         font-size: 100px;
         bottom: 10%;
      }
   }

   //icon
   .icon-styled {

      //bordered icon and icon with background
      &[class*='bg-'],
      &.icon-bordered {
         margin-bottom: 0.5em;
      }

      & + h2,
      & + h3,
      & + h4 {
         margin-top: 1.6em;
      }

      //for counter teaser with counter h3 tag below p description
      & + p.small-text {
         font-size: 20px;
         font-weight: 300;
         letter-spacing: 0.2em;
         margin: 0.74em 0;
      }
   }

   //headings style
   h2, h3, h4 {
      margin-bottom: 1.6em;
      line-height: 1;
   }
}

.icon-styled,
.icon-box {
   position: relative;

   .iq-rotate-text {
      position: relative;
      font-size: 14px;
      letter-spacing: .2em;
      text-transform: uppercase;

      div {
         animation: iqrotation 25s infinite linear;
         animation-play-state: running;
      }

      & + * {
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
      }
   }

   .icon-styled:hover {
      &:hover .iq-rotate-text div {
         animation: iqrotation 10s infinite linear;
         animation-play-state: paused;
      }
   }

   @keyframes iqrotation {
      from {
         -webkit-transform: rotate(0deg)
      }

      to {
         -webkit-transform: rotate(359deg)
      }
   }
}

.box-counter-wrap {
   h3 {
      font-weight: 800;
      font-size: 80px;
      opacity: .3;
      line-height: .8;
      @media (min-width: 1400px) {
         font-size: 120px;
      }
   }

   .icon-box + *{
      margin-top: -30px!important;
   }
}

.media,
.icon-box {
   background-size: cover;
   //accent heading
   .icon-styled {
      position: relative;
   }

   .heading-accent {
      font-weight: 900;
      text-transform: uppercase;
      letter-spacing: inherit;
      font-size: 30px;
      margin-bottom: 0.6em;
      line-height: 1;

      @media (min-width: 1200px) {
         font-size: 38px;
      }
   }

   .icon-styled + .heading-accent {
      margin-top: 0.6em;
   }


   //special heading
   .special-heading {
      margin: 0;
   }

   h1,
   h2,
   h3,
   h4,
   h5,
   h6 {
      [class*='ico'] {
         font-size: 15px;
         position: relative;
         transform: translateY(-15%);
         margin-left: 10px;
      }
   }

   a {
      display: inline-block;
   }
}

.media {
   &.ls,
   &.ds,
   &.cs,
   &.bordered,
   &.hero-bg,
   &.muted-bg,
   &.box-shadow {
      padding: 30px;

      [class*='bg-'],
      .icon-bordered {
         margin-right: 10px;

         &:last-child {
            margin-right: 0;
            margin-left: 30px;
         }
      }

   }

   .media-body > p:first-child {
      margin-top: -0.2em;
   }
}

.side-icon-box {
   margin: 15px 0 32px;

   h4 {
      text-transform: uppercase;
      letter-spacing: 0.17em;
      margin-top: 0;
      margin-bottom: 15px;
   }

   @media (min-width: 1200px) {
      .icon-styled + .media-body {
         margin-left: 50px;
      }
      & {
         margin-bottom: 50px;
      }
   }
}

.vertical_box.media {
   .media-body {
      margin-top: 0;
   }

   align-items: center;
   flex-direction: row;

   h5 {
      margin-bottom: 0;
      margin-top: 0;
   }

   &.big-media {
      .icon-styled {
         flex-shrink: 1;

         a {
            border-radius: 50%;
            padding: 10px;
            border: 1px solid $borderColor;
         }

         img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: 50%;
         }
      }

      &:hover {
         img {
            transform: scale(1.05);
         }
      }

      @media (max-width: 767px) {
         flex-direction: column;
         align-items: flex-start;
         * + .media-body {
            margin-top: 30px;
            margin-left: 0;
         }
      }
   }
}

.ls {
   .iq-rotate-text {
      color: $darkColor;
   }
}

.ds {
   .iq-rotate-text {
      color: $fontColor;
   }
}
